<template>
  <div
    class="bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-200 md:ml-32 h-full"
  >
    <NavBar v-model:nav="nav" />
    <router-view />
  </div>
</template>

<script>
import { ref, onBeforeMount, onBeforeUnmount, watch } from 'vue'
import NavBar from '@/components/NavBar.vue'

export default {
  name: 'App',
  components: { NavBar },
  setup() {
    const nav = ref(false)
    const width = ref(0)

    function handleResize() {
      width.value = window.innerWidth
    }

    watch(width, width => {
      nav.value = width < 640 ? false : true
    })

    onBeforeMount(() => {
      nav.value = window.innerWidth < 640 ? false : true
      window.addEventListener('resize', handleResize)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize)
    })

    return {
      nav
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Roboto&display=swap');

html,
body,
#app {
  height: 100%;
}

* {
  font-family: 'Roboto', sans-serif;
}

.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.4s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
