<template>
  <nav
    class="bg-lightBlue-400 text-gray-800 top-0 grid grid-cols-2 fixed w-full z-10 sm:flex md:flex-col sm:justify-between md:left-0 md:h-full md:w-32 md:fixed"
  >
    <div class="p-2 self-center order-1">
      <h1
        class="text-left text-2xl cursor-pointer md:text-center"
        @click="$router.push({ name: 'Home' })"
      >
        Daniel
      </h1>
    </div>
    <transition name="slide-fade">
      <ul
        class="order-3 col-span-2 self-center flex justify-center fixed w-full mt-11 bg-lightBlue-400 sm:static sm:w-auto sm:mt-0 sm:order-2 md:block md:self-stretch"
        v-if="nav"
      >
        <router-link
          v-for="(link, index) in links"
          :key="link.name + index"
          class="py-3 flex justify-center w-full hover:bg-lightBlue-300 sm:py-4 sm:px-8 md:px-0 md:py-4"
          :to="link.route"
        >
          <i class="fas self-center md:mr-2" :class="link.icon"></i>
          <h2 class="hidden text-sm md:block">{{ link.name }}</h2>
        </router-link>
      </ul>
    </transition>
    <div
      class="flex justify-end self-center order-2 sm:order-3 md:order-3 md:justify-center md:flex-col"
    >
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.github.com/danydlo"
        class="p-2 justify-center hover:text-gray-700 flex self-center"
      >
        <i class="fab fa-github"></i>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.twitter.com/danydlo"
        class="p-2 justify-center hover:text-gray-700 flex self-center"
      >
        <i class="fab fa-twitter"></i>
      </a>
      <span
        class="material-icons cursor-pointer p-2 hover:bg-lightBlue-300 rounded-full"
        v-show="theme === 'light'"
        @click="darkTheme"
      >
        dark_mode
      </span>
      <span
        class="material-icons cursor-pointer p-2 hover:bg-lightBlue-300 rounded-full"
        v-show="theme === 'dark'"
        @click="lightTheme"
      >
        light_mode
      </span>
      <span
        class="material-icons cursor-pointer self-center text-4xl ml-2 sm:hidden"
        @click="change"
        >menu</span
      >
    </div>
  </nav>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'NavBar',
  props: ['nav'],
  emits: ['update:nav'],
  setup(props, { emit }) {
    const links = ref([
      { name: 'Home', route: '/', icon: 'fa-home' },
      { name: 'About', route: '/about', icon: 'fa-user' },
      { name: 'My Work', route: '/work', icon: 'fa-briefcase' },
      { name: 'Contact', route: '/contact', icon: 'fa-paper-plane' }
    ])
    const theme = ref(localStorage.theme || 'light')

    function change() {
      emit('update:nav', !props.nav)
    }

    function darkTheme() {
      theme.value = localStorage.theme = 'dark'
      document.querySelector('html').classList.add('dark')
    }

    function lightTheme() {
      theme.value = localStorage.theme = 'light'
      document.querySelector('html').classList.remove('dark')
    }

    return {
      change,
      links,
      theme,
      darkTheme,
      lightTheme
    }
  }
}
</script>

<style scoped>
h1 {
  font-family: 'Audiowide', cursive;
}

.router-link-active,
.router-link-exact-active {
  background-color: #7dd3fc;
}
</style>
