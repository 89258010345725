<template>
  <div class="h-full flex flex-col justify-center items-center">
    <Particles
      id="tsparticlesdark"
      :options="options"
      class="fixed h-full w-full dark:z-5"
    />
    <h1
      class="text-gray-800 dark:text-gray-100 text-2xl font-bold tracking-wider md:text-4xl lg:text-5xl"
    >
      Welcome, I'm <span class="text-lightBlue-500">Daniel De La O.</span>
    </h1>
    <h1
      class="text-gray-800 dark:text-gray-100 text-2xl font-bold tracking-wider mt-2 mb-4 md:mt-4 md:mb-6 md:text-4xl lg:text-5xl"
    >
      I'm a software developer.
    </h1>
    <button
      class="btn md:text-xl lg:text-2xl"
      @click="$router.push({ name: 'Contact' })"
    >
      Contact me!
    </button>
  </div>
</template>

<script>
import options from '@/utils/particleOptions'

export default {
  name: 'Home',
  data() {
    return {
      options
    }
  }
}
</script>
