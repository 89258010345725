import { createApp } from 'vue'
import { init } from 'emailjs-com'
import App from './App.vue'
import '@/assets/css/tailwind.css'
import router from './router'
import Particles from 'particles.vue3'

// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (
  localStorage.theme === 'dark' ||
  (!('theme' in localStorage) &&
    window.matchMedia('(prefers-color-scheme: dark)').matches)
) {
  localStorage.theme = 'dark'
  document.querySelector('html').classList.add('dark')
} else {
  localStorage.theme = 'light'
  document.querySelector('html').classList.remove('dark')
}
// initiate emailjs
init(process.env.VUE_APP_USER_ID)

const app = createApp(App)

app.use(router)
app.use(Particles)

router.isReady().then(() => {
  app.mount('#app')
})
